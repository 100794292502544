<template>
  <v-container fluid>
    <v-row class="my-10">
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
      <v-col>
        <payment-successful-card></payment-successful-card>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
    </v-row>
  </v-container>
</template>

<script>
import PaymentSuccessfulCard from '../../components/card/PaymentSuccessfulCard.vue'
export default {
    name: "payment-success-page",
    components: {
        PaymentSuccessfulCard
    },
}
</script>

<style>

</style>